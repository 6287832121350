@import url("https://fonts.googleapis.com/css?family=Montserrat|Mrs+Sheppards|Poppins&display=swap");
@import "tailwindcss/base";
@import "tailwindcss/components";
// @import "./base.css";
@import "tailwindcss/utilities";

:root {
  --black-1: #212934;
}
* {
  font-family: "Montserrat", sans-serif;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

#sidebar {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: 13vw;
  width: max-content;
  margin: 0 5px;
  color: white;
  position: sticky;
  top: 0;
  left: 0;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap:10px;
    border-radius: 10px;
    background-color: #212934;
    border: 1px solid #212934;
    margin: 5px 0;
    padding: 4px 8px;
    box-shadow: -5px 5px 5px #212934b7;
    img {
      height: 4vh;
      width: auto;
    }
    .content {
      width: 8vw;
      overflow: hidden;
      margin-left: 5px;
      h1 {
        font-weight: 800;
        font-size: 1.8vh;
        text-wrap: nowrap;
        transition: 100ms ease;
      }
      h2 {
        font-size: 0.8rem;
        transition: 100ms ease;
      }
    }
    .arrow {
      width: 1rem;
      width: 2vh;
      fill: white;
      transition: 100ms ease;
    }
    transition: 200ms ease;
    &:hover {
      border: 1px solid #212934;
      background-color: transparent;
      color: black;
      box-shadow: -5px 5px 10px #212934b7;
      h1,
      h2 {
        // text-decoration: underline;
        font-weight: 600;
        transition: 100ms ease;
      }
      svg {
        fill: black;
        transition: 100ms ease;
      }
      transition: 200ms ease;
    }
  }
  .body {
    margin: 5px 0;
    width: 100%;
    border-radius: 10px;
    background-color: #212934;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    overflow-y: auto;
    box-shadow: -5px 5px 10px #212934b7;
    .content {
      -ms-overflow-style: none; /* For IE and Edge */
      scrollbar-width: none;    /* For Firefox */
      overflow-y: scroll;       /* Enable vertical scrolling */
      position: relative;       /* Positioning for the container */

      /* Hide scrollbar by default */
      &::-webkit-scrollbar {
        display: none; /* For Chrome, Safari, and Opera */
      }

      /* On hover, only show the scrollbar thumb */
      &:hover {
        scrollbar-width: thin; /* For Firefox (shows thin scrollbar with thumb only) */
        scrollbar-base-color: transparent;
        scrollbar-color: green;
        scrollbar-track-color: transparent;
      }

      &:hover::-webkit-scrollbar {
        display: block; /* Ensure the scrollbar is displayed */
        width: 0.25rem;
      }

      // &:hover::-webkit-scrollbar-thumb {
      &::-webkit-scrollbar-thumb {
        background-color: rgb(255, 0, 0); /* Darker scroll thumb */
        border-radius: 10px; /* Rounded corners for the thumb */
      }
      
      &::-webkit-scrollbar-track {
        background: rgba(7, 7, 7, 0); /* Hide the track */
      }

      // /* Hide the scrollbar buttons */
      // &::-webkit-scrollbar-button {
      //   display: none; /* Hide the up/down arrow buttons */
      // }
      height: 100%;
      & > ul {
        margin: 10px 0;
        li {
          padding: 0 2px;
          display: flex;
          justify-content: start;
          align-items: center;
          font-size: 0.9rem;
          font-size: 1.9vh;
          color: #9699a9;
          svg {
            width: 16px;
            margin: 10px;
            width: 1.9vh;
            fill: #9699a9;
            // fill: #676b83;
            height: auto;
            transition: 200ms ease;
            &.r {
              width: 14px;
              margin-left: auto;
              margin-right: 10px;
            }
          }

          border-left: 5px solid transparent;
          transition: 200ms ease;
          &:hover {
            color: #dbdddc;
            svg {
              fill: #dbdddc;
              transition: 200ms ease;
            }
            transition: 200ms ease;
          }
          &.selected {
            background-color: #151e2a;
            color: #dbdddc;
            border-left: 5px solid red;
            svg {
              fill: #dbdddc;
            }
          }
        }

        .dropdown-menu {
          overflow: hidden;
          max-height: 0;
          transition: max-height 0.5s ease-in;
          &.open {
            max-height: 500px;
          }
          background-color: rgb(42, 51, 63);
          & li {
            padding: 5px;
            padding-left: 40px;
            color: #676b83;
            &.selected {
              background-color: #242c37;
              color: #dbdddc;
              border-left: none;
              svg {
                fill: #dbdddc;
              }
            }
            &:hover {
              color: #dbdddc;
            }
            // background-color: transparent;
            // border-bottom: 1px solid #ddd;
            // &:last-child {
            //   border-bottom: none;
            // }
          }
        }
      }
    }
    .bottom {
      padding: 10px 20px;
      border-top: 3px solid #676b83;
      h1 {
        font-size: 1.3vw;
        font-weight: 600;
        font-size: 2.1vh;
        margin: 5px 0;
      }
      h2 {
        font-size: 1vw;
        font-size: 1.8vh;
      }
      button {
        font-size: 0.9rem;
        border: 1px solid white;
        border-radius: 5px;
        padding: 5px 10px;
        margin: 10px 0;
        transition: 100ms ease;
        font-weight: 500;
        font-size: 1.8vh;
        &:hover {
          background-color: white;
          color: var(--black-1);
          font-weight: 700;
          transition: 100ms ease;
        }
      }
    }
  }
}

#nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  background-color: white;
  height: 8vh;
  padding: 5px;
  top: 0;
  z-index: 1;
  .inp {
    width: 100%;
    height: 100%;

    padding: 5px 0;
    margin: 20px;
    input {
      height: 100%;
      width: 100%;
      padding: 7px 20px;
      border-radius: 50px;
      border: 1px solid rgba(128, 128, 128, 0.584) (128, 128, 128, 0.584);
      box-shadow: -2px 2px 8px #21293452;
      transition: 200ms ease;

      font-size: 2vh;
      &:hover {
        box-shadow: -2px 2px 6px #2129348b;
        transition: 200ms ease;
      }
      &:focus {
        outline: none;
        transition: 200ms ease;
        background-color: rgba(204, 204, 204, 0.478);
      }
    }
    position: relative;
    ul {
      background-color: white;
      border: 2px solid rgb(219, 219, 219);
      border-radius: 20px;
      overflow-x: hidden;
      margin: 5px;
      width: 100%;
      position: absolute;
      max-height: 420px;
      // padding: 0 10px;
      -ms-overflow-style: none; /* for Internet Explorer, Edge */
      scrollbar-width: none; /* for Firefox */
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
      }
      li {
        padding: 10px 20px;
        font-weight: 600;
        border-bottom: 1px solid gray;
        transition: 100ms ease;
        &:hover {
          background-color: rgb(217, 217, 217);
          transition: 100ms ease;
        }
      }
    }
  }

  & > button {
    margin: 10px;
    width: 20vw;
    padding: 5px 0;
    border-radius: 10px;
    font-size: 2vh;
    font-weight: 500;
    box-shadow: -2px 2px 8px #222a353a;
    height: calc(100% - 12px);
    &.purchase {
      border-radius: 50px;
      background-color: #6e96ff7a;
      // border: 1px solid #346aff;
      color: #346aff;
      transition: 200ms ease;
      &:hover {
        box-shadow: -2px 2px 8px #212934c0;
        background: #346aff;
        color: white;
        transition: 200ms ease;
      }
    }
    &.addSale {
      border-radius: 50px;
      background-color: #ff0d0d29;
      // border: 1px solid #ff0d0d;
      color: #ff0d0d;
      transition: 200ms ease;
      &:hover {
        box-shadow: -2px 2px 8px #212934c0;
        background: #ff0d0d;
        color: white;
        transition: 200ms ease;
      }
    }
    &.addMore {
      border-radius: 50px;
      background-color: transparent;
      border: 1px solid #8ca8f6;
      padding: 4px 4px;
      color: #346aff;
      transition: 200ms ease;
      &:hover {
        box-shadow: -2px 2px 8px #212934c0;
        border: 3px solid #002a9d;
        padding: 2px 2px;
        background: #002a9d;
        // border: transparent;
        color: white;
        transition: 200ms ease;
        .drop {
          color: black;
        }
      }
      position: relative;
      .drop {
        position: absolute;
        opacity: 0;
        pointer-events: none;
        transition: 200ms ease;
        &.active {
          opacity: 1;
          pointer-events: all;
          // display: block;
          transition: 200ms ease;
        }
        border: 1px solid rgb(165, 165, 165);
        border-radius: 5px;
        background-color: rgb(255, 255, 255);
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        align-items: start;
        transform: translate(-50%, 10%);
        .l,
        .r {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;
          width: 150px;
        }
        p {
          color: black;
          font-weight: 600;
          font-size: 1.1rem;
          width: 100%;
          border-bottom: 1px solid gray;
          padding-bottom: 5px;
        }
      }
      button {
        width: fit-content;
        text-align: center;
        color: gray;
        padding: 5px;
        border-bottom: 1px solid #002a9d00;
        // text-wrap: nowrap;
        min-width: 150px;
        font-weight: 600;
        &:hover {
          color: #00a7f5;
          font-weight: 600;
          text-decoration: underline;
          // border-bottom: 1px solid #002a9d;
        }
      }
    }
    &:hover {
      font-weight: 600;
      transition: 200ms ease;
    }
  }
}
#Home {
  display: flex;
  justify-content: start;
  // gap: 5px;
  & > .body {
    width: 100%;
    height: 100vh;
    padding: 0 5px;
    overflow-y: scroll;
  }
}
#profile {
  height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .profileD {
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 30vw;
    align-items: center;
    justify-content: center;
    padding: 20px;
    // box-shadow: -5px 5px 10px rgba(53, 53, 53, 0.536);
    border-radius: 5px;
    div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
    }
    h1 {
      font-size: 1vw;
      font-weight: 400;
    }
    span {
      font-size: 1.2vw;
      font-weight: 600;
    }
    button {
      padding: 10px 30px;
      font-size: 1.3rem;
      color: rgb(255, 255, 255);
      font-weight: 600;
      width: 100%;
      background: rgb(0, 57, 114);
      border-radius: 10px;
    }
  }
}
#login {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e6e6e6;
  .content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: white;
    height: 80vh;
    width: 90%;
    overflow: visible;
    .left {
      height: 90vh !important;
      overflow: visible;
      background-color: #0090d3;
      box-shadow: 5px 5px 10px rgb(137, 137, 137);
      width: 40vw;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      color: white;
      align-items: center;
      padding: 30px;
      border-radius: 10px;
      h1 {
        width: 100%;
        font-size: 2.4rem;
        font-weight: 600;
        text-align: center;
      }
      p {
        width: 100%;
        font-size: 1.2rem;
        font-weight: 500;
        text-align: center;
      }
      img {
        height: 60%;
      }
    }
    .right {
      // height: fit-content;
      width: 30vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      gap: 5px;
      // box-shadow: 5px 5px 10px rgb(172, 172, 172);
      // border-radius: 10px;
      .line {
        border-bottom: 1px solid rgb(130, 130, 130);
        width: 100%;
        height: 1px;
        margin: 10px 0;
      }
      .form {
        min-height: 25vw;
        height: auto;
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        h1 {
          color: #0090d3;
          margin: 20px 0 !important;
          font-size: 2.1rem;
          font-weight: 800;
          margin: 10px 0;
        }
        input {
          padding: 5px 10px;
          font-size: 1.1rem;
          width: 95%;
          border: none;
          text-align: center;
          border-bottom: 2px solid rgb(138, 138, 138);
          margin: 10px 0;
          &:focus {
            border: none;
            outline: none;
            border-bottom: 2px solid rgb(0, 0, 0);
          }
        }
        button {
          padding: 5px 40px;
          margin: 10px 0;
          border-radius: 5px;
          width: 100%;
          border: none;
          background-color: transparent;
          color: var(#0f1b4c);
          background-color: #0090d3;
          font-weight: 600;
          color: white;
          font-size: 1.2rem;
          transition: 100ms ease-in-out;
          border: 2px solid #0090d3;
          &:hover {
            color: #0090d3;
            font-weight: 600;
            background: transparent;
            border: 2px solid #0090d3;
            transition: 100ms ease-in-out;
          }
          &.google {
            border: 1px solid #151e2a;
            background-color: #151e2a;
            color: white;
            display: flex;
            justify-content: center;
            gap: 20px;
            align-items: center;
            img {
              height: 1rem;
              width: auto;
            }
            &:hover {
              background-color: transparent;
              color: black;
            }
          }
        }
      }
    }
  }
}

#Landing {
  nav {
    display: flex;
    justify-content: space-between;
    padding: 5px 20px;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 5;
    background-color: rgb(255, 255, 255);
    .icon svg {
      // height: 50px;
    }
    .mid {
      display: flex;
      gap: 30px;
      align-items: center;
      a {
        font-size: 1.1rem;
        text-decoration: none;
        color: #4f5361;
        font-weight: 500;
      }
    }
    .controls {
      display: flex;
      gap: 10px;
      align-items: center;
      a {
        font-size: 1rem;
        padding: 5px 20px;
        font-weight: 600;
        border-radius: 5px;
        border: none;
        background-color: transparent;
        text-decoration: none;
        color: var(#0f1b4c);
        &.signup {
          background-color: #0090d3;
          color: white;
          transition: 100ms ease-in-out;
          &:hover {
            color: black;
            background: transparent;
            border: 2px solid #0090d3;
            transition: 100ms ease-in-out;
          }
        }
      }
    }
  }
  .landing {
    min-height: 100vh;
    background-image: url(../public/assets/landing/landing.png);
    background-size: cover;
    background-repeat: no-repeat;

    position: relative;
    img {
      width: 40vw;
      position: absolute;
      right: 10%;
      bottom: 0;
    }
    .left {
      width: 40vw;
      position: absolute;
      left: 30%;
      top: 50%;
      transform: translate(-50%, -50%);
      h1 {
        font-size: 3rem;
        // margin: 5px 0px;
        font-weight: 500;
        line-height: normal;
        span {
          font-size: 4.5rem;
          font-weight: 900;
          color: #0090d3;
          margin-bottom: 20px;
        }
      }
      p {
        font-size: 1.2rem;
        margin: 20px 0px;
      }
      button {
        padding: 5px 40px;
        border-radius: 5px;
        border: none;
        background-color: transparent;
        color: var(#0f1b4c);
        background-color: #0090d3;
        color: white;
        font-size: 1.2rem;
        transition: 100ms ease-in-out;
        &:hover {
          color: black;
          background: transparent;
          border: 2px solid #0090d3;
          transition: 100ms ease-in-out;
        }
      }
    }
  }
  .one {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    p {
      margin: 20px;
      font-size: 1.4rem;
      color: #0f1b4c;
      font-weight: 800;
    }
    .icons {
      width: 80%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin: 30px;
    }
  }
  .two {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > h1 {
      margin: 20px;
      font-size: 1.5rem;
      font-weight: 600;
      span {
        color: #0090d3;
      }
    }
    & > p {
      width: 60vw;
      text-align: center;
    }
    .tiles {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;
      margin: 50px;
      & > div {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: 25vw;
        height: 400px;
        border-radius: 10px;
        box-shadow: 0px 5px 10px rgba(128, 128, 128, 0.758);
        position: relative;
        padding: 30px;
        transition: 300ms ease;
        &:hover {
          scale: 1.05;
          transition: 300ms ease;
        }
        .icon {
          svg {
            height: 30px;
            margin: auto auto;
          }
          position: absolute;
          top: 0;
          left: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          transform: translate(-50%, -50%);
          width: 60px !important;
          height: 60px;

          // background-color: #0090d3;
          width: fit-content;
          border-radius: 100px;
          // padding: 10px;
        }
        h1 {
          width: 100%;
          font-size: 1.5rem;
          text-align: center;
        }
        p {
          text-align: center;
        }
      }
    }
  }
  .three {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .feature_nav {
      // background-color: rgba(255, 255, 255, 0.736);
      position: sticky;
      top: 70px;
      width: 100%;
      left: 0;
      padding: 20px 0;
      padding-top: 0;
      h1 {
        text-align: center;
        font-size: 2.2rem;
        font-weight: 600;
        padding: 10px 0;
      }
      .btns {
        width: 100%;
        gap: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          padding: 5px 10px;
          font-size: 0.8rem;
          border: 1px solid rgb(219, 219, 219);
          border-radius: 5px;
          font-weight: 600;
          transition: 100ms ease;
          &.active {
            border: 1px solid white;
            color: white;
            background-color: #3b3b3b5d;
          }
        }
      }
    }
    .feature_tiles {
      width: 90vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 40px 0;
      & > img,
      & > svg {
        max-height: 50vh;
      }
      div {
        width: 50vw;
        h1 {
          font-size: 2.2rem;
          // margin-left: 10px;
          // border-bottom: 2px solid rgb(217, 217, 217);
          // padding: 10px 0;
          font-weight: 600;
          margin: 10px 0;
        }
        h2 {
          font-size: 1.4rem;
          // margin-left: 10px;
          // border-bottom: 2px solid rgb(217, 217, 217);
          // padding: 10px 0;
          font-weight: 500;
          margin: 10px 0;
          margin-bottom: 20px;
        }
        p {
          font-size: 1.1rem;
          color: #696984;
        }
        button {
          padding: 5px 40px;
          border-radius: 5px;
          border: none;
          background-color: transparent;
          color: var(#0f1b4c);
          background-color: #2f92c0;
          border: 2px solid #0090d3;
          color: white;
          font-size: 1.2rem;
          margin: 20px 0px;
          font-weight: 600;
          transition: 100ms ease-in-out;
          &:hover {
            color: #0090d3;
            background: transparent;
            border: 2px solid #0090d3;
            transition: 100ms ease-in-out;
          }
        }
      }
    }
  }
  .four {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    background-color: #0090d3;
    h1 {
      color: white;
      font-size: 2.1rem;
      span {
        color: #ffe500;
      }
    }
    .btn {
      display: flex;
      gap: 20px;
      a {
        img {
          height: 50px;
        }
      }
      button {
        // margin: 20px;
        height: 50px;
        width: 200px;
        font-size: 1.2rem;
        font-weight: 600;
        background-color: #ffe500;
        border: none;
        border-radius: 10px;
        transition: 100ms ease-in-out;
        &:hover {
          color: #ffe500;
          background: transparent;
          border: 3px solid #ffe500;
          transition: 100ms ease-in-out;
        }
      }
    }
  }
  .five {
    min-height: 80vh;
    padding: 10vh;
    h1 {
      font-size: 2.2rem;
      font-weight: 600;
      padding: 10px 0;
      margin: 10px 0;
    }
    .sec {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        height: 70vh;
      }
      .qna {
        background: #75d3ff;
        width: 40vw;
        height: 60vh;
        border-radius: 20px;
      }
    }
  }

  .footer {
    background-color: #0090d3;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 10vh;
    .left {
      width: 25vw;
      p {
        margin: 10px 0;
      }
      .socials {
        width: 100%;
        display: flex;
        gap: 20px;
        align-items: center;
      }
    }
    .mid {
      display: flex;
      flex-direction: column;
      gap: 20px;
      a {
        color: white;
      }
    }
    .right {
      width: 20vw;
    }
  }
}

#Dashboard {
  .topbar {
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding: 0 20px;
    background-color: #f9f8f2;
    border-radius: 10px;
    button {
      padding: 5px 10px;
      font-weight: 700;
      border-bottom: 2px solid rgba(182, 0, 0, 0);
      transition: 200ms ease;
      &.selected {
        border-bottom: 2px solid red;
      }
      &:hover {
        border-bottom: 1px solid rgb(255, 174, 158);
        transition: 200ms ease;
      }
    }
  }
  .content {
    height: 100%;
    display: flex;
    .left {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      width: 100%;
      padding: 0 10px;
      gap: 20px;
      padding-top: 10px;
      height: fit-content;
      & > .tile {
        padding: 10px;
        // margin: 15px;
        border-radius: 10px;
        box-shadow: 0px 0px 5px rgba(136, 136, 136, 0.586);
        background: rgb(205, 205, 205);
        max-height: 12vw;
        overflow: hidden;
        &.sale {
          background: linear-gradient(
            153.78deg,
            #f4e0e0 16.5%,
            rgba(235, 211, 211, 0.72) 52.24%,
            #ffe0e0 83.3%
          );
        }
        &.profit {
          background: linear-gradient(
            147.27deg,
            #eef3f8 19.56%,
            rgba(212, 223, 233, 0.72) 51.84%,
            #eceff2 86.26%
          );
        }
        &.collect {
          background: linear-gradient(180deg, #e5ecf6 0%, #f3f8fc 100%);
        }
        &.pay {
          background: linear-gradient(180deg, #fefff6 0%, #f5f5df 100%);
        }
        &.purchase {
          background: linear-gradient(180deg, #fff4de 0%, #fffdf9 100%);
        }
        &.expense {
          background: linear-gradient(180deg, #f3e8ff 0%, #f1eef4 100%);
        }
        &.cash {
          background: #e5ffee;
        }
        &.score {
          background: linear-gradient(180deg, #fce4e4 0%, #f7efef 100%);
        }

        .top {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 10px;
          .title {
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
              aspect-ratio: 1/1;
              width: 20px;
              margin-right: 10px;
            }
            h1 {
              font-size: 1.1rem;
              font-weight: 600;
            }
          }
          select {
            border: none;
            background-color: transparent;
          }
          & > svg {
            height: 20px;
            width: auto;
          }
        }
        .mid {
          h1 {
            font-size: 1.4rem;
            font-weight: 500;
            margin:2px 0;
          }
          h2 {
            font-size: 1rem;
            font-weight: 500;
            margin: 10px 0;
            display: flex;
            svg {
              width: 20px;
              margin-right: 10px;
              fill: green;
              height: auto;
            }
          }
          .tile {
            display: flex;
            justify-content: space-between;
            width: 90%;
          }
        }
        position: relative;
        .profile {
          position: absolute;
          bottom: 10px;
          right: 10px;
          svg {
            width: 15px;
            height: auto;
          }
        }
        transition: 200ms ease-in-out;
        &:hover {
          box-shadow: -5px 5px 10px rgba(136, 136, 136, 0.43);
          scale: 1.05;
          transition: 200ms ease-in-out;
        }
      }
    }
    .right {
      width: 30%;
      padding: 10px 10px;
      margin: 0 10px;
      border-left: 3px solid #1c1c1c1a;
      .list {
        border: 1px solid #eaeaea;
        border-radius: 10px;
        padding: 10px;
        margin: 10px;
        background: #f9f8f280;
        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          h1 {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 1rem;
            svg {
              width: 12px;
              margin-left: 10px;
            }
          }
          svg {
            height: 20px;
          }
        }
      }
      .todo {
        ul {
          margin: 10px 0;
          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            list-style-type: none;
            label {
              font-weight: 500;
              display: flex;
              align-items: center;
              font-size: 1.1rem;
              input {
                margin-right: 10px;
              }
            }

            button {
              padding: 2px 25px;
              background-color: rgb(255, 84, 84);
              color: white;
              font-weight: 700;
              border-radius: 5px;
              font-size: 0.8rem;
            }
          }
        }
        & > button,
        & > div button {
          border: 2px solid gray;
          border-radius: 5px;
          padding: 2px 20px;
          transition: 300ms ease;
          &:hover {
            background-color: rgb(39, 39, 39);
            color: white;
            font-weight: 600;
            transition: 300ms ease;
          }
        }
        & > div input {
          background-color: transparent;
          border-bottom: 1px solid black;
          padding: 2px 10px;
          margin-right: 10px;
        }
      }
    }
  }
}

#parties {
  display: flex;
  padding-right: 20px;
  .left {
    background-color: #f9f8f2;
    flex: 1;
    border-radius: 10px;
    margin: 0 10px;
    .top {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      .btn {
        display: flex;
        background: orange;
        border: none;
        padding: 5px 15px;
        color: white;
        svg {
          fill: white;
        }
        border-radius: 10px;
        transition: 100ms ease;
        button:hover {
          svg {
            fill: rgb(48, 48, 48);
            transition: 100ms ease;
          }
          color: rgb(48, 48, 48);
          transition: 100ms ease;
        }
      }
      div {
        display: flex;
        align-items: center;
        gap: 10px;
        svg {
          height: 20px;
        }
      }
    }
    .content {
      margin-top: 20px;
      .head {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        h2 {
          &:hover{
            text-decoration: underline;
          }
          font-weight: 600;
        }
      }
      .tile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        &.selected {
          background-color: #d4f7ea;
        }
        position: relative;
        .hovEle {
          display: none;
          transition: 300ms ease;
          position: absolute;
          border: 1px solid gray;
          background: white;
          z-index: 2;
          border-radius: 2px;
          padding: 2px 5px;
          top: 40px;
        }
        h1 {
          max-width: 70%;
          overflow: hidden;
          // &:hover {
          //   position: absolute;
          //   width: fit-content;
          //   background-color: rgba(255, 255, 255, 0.858);
          //   padding: 5px;
          //   // border: 1px solid gray;
          //   z-index: 1;
          // }
        }
        div {
          display: flex;
          align-items: center;
          gap: 10px;
          p {
            color: red;
            font-weight: 600;
            &.textGreen {
              color: rgb(3, 230, 3);
            }
          }
          svg {
            height: 15px;
          }
        }
        transition: 100ms ease;
        &:hover {
          background: rgba(164, 164, 164, 0.218);
          transition: 100ms ease;
          .hovEle {
            display: block;
          }
        }
      }
    }
  }
  .right {
    flex: 3;
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      background-color: #f9f8f2;
      border-radius: 10px;
      .tile {
        display: flex;
        align-items: center;
        gap: 10px;
        svg {
          height: 20px;
        }
        div {
        }
      }
    }
    .title {
      display: flex;
      justify-content: space-between;
      min-height: 10vw;
      padding: 20px;
      background-color: #d4f7ea;
      border-radius: 10px;
      margin: 10px 0;
    }
    .content {
      background-color: #f9f8f2;
      .t {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        h1 {
          font-size: 1.2vw;
        }
        .search {
          border: 1px solid grey;
          border-radius: 50px;
          display: flex;
          align-items: center;
          svg {
            margin: 5px 10px;
            height: 15px;
          }
          input {
            height: 100%;
            border: none;
            background-color: transparent;
            font-size: 1.2vw;
          }
        }
      }
      .cl {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        // border-bottom: 1px solid gray;
        margin: 10px;
        border-radius: 10px;
        background-color: #d0e4d9;
        &.top {
          background-color: #bedfd3;
          border-radius: 10px;
        }
        &:nth-child(odd) {
          background-color: #def0e9;
        }
        p {
          font-weight: 600;
          text-align: center;
          flex: 1;
          &.grey {
            font-weight: 400;
          }
          &.side {
            flex: none;
          }
        }
        svg {
          fill: black;
          height: 1rem;
        }
      }
    }
  }
}

#items {
  .topbar {
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding: 0 20px;
    margin-bottom: 20px;
    background-color: #f9f8f2;
    border-radius: 10px;
    button {
      padding: 5px 10px;
      font-weight: 700;
      border-bottom: 2px solid rgba(182, 0, 0, 0);
      transition: 200ms ease;
      &.selected {
        border-bottom: 2px solid red;
      }
      &:hover {
        border-bottom: 1px solid rgb(255, 174, 158);
        transition: 200ms ease;
      }
    }
  }
  .items {
    display: flex;
    padding-right: 20px;
    .left {
      background-color: #f9f8f2;
      flex: 1;
      border-radius: 10px;
      margin: 0 10px;
      .top {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        & > button {
          background: orange;
          border: none;
          padding: 5px 15px;
          color: white;
          border-radius: 10px;
          transition: 100ms eas;
          &:hover {
            background-color: rgb(255, 180, 40);
            transition: 100ms ease;
          }
        }
        #ItemCategory {
          position: absolute;
          z-index: 2;
          top: 0;
          left: 0;
          height: 100vh;
          width: 100vw;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(0, 0, 0, 0.658);
          animation: fadeIn 100ms ease;
          .center {
            display: flex;
            flex-direction: column;
            background-color: white;
            border-radius: 10px;
            gap: 0;
            min-width: 30vw;
            .t {
              padding: 20px;
              width: 100%;
              border-bottom: 1px solid gray;
              display: flex;
              align-items: center;
              justify-content: space-between;
              h1 {
                font-size: 1.4rem;
                font-weight: 600;
              }
            }
            .m {
              padding: 20px;
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: start;
              input {
                border: 1px solid gray;
                width: 100%;
                padding: 10px 20px;

                font-size: 1.1rem;
                &:focus {
                  outline: none;
                }
              }
              button {
                width: 100%;
                text-align: center;
                padding: 10px;
                background: red;
                color: white;
                font-weight: 600;
                margin-top: 30px;
                border-radius: 50px;
              }
            }
          }
        }
        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        div {
          display: flex;
          align-items: center;
          gap: 10px;
          svg {
            height: 20px;
          }
        }
      }
      .content {
        margin-top: 20px;
        .head {
          display: flex;
          justify-content: space-between;
          padding: 10px;
          h2 {
            font-weight: 600;
            &:hover{
              text-decoration: underline;
            }
          }
        }
        .tile {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          &.selected {
            background-color: #d4f7ea;
          }
          .hovEle {
            display: none;
            transition: 300ms ease;
            position: absolute;
            border: 1px solid gray;
            background: white;
            z-index: 2;
            border-radius: 2px;
            padding: 2px 5px;
            top: 40px;
          }
          h1 {
            width: 60%;
          }
          div {
            display: flex;
            align-items: center;
            gap: 10px;
            p {
              color: red;
              font-weight: 600;
            }
            svg {
              height: 15px;
            }
          }
          transition: 100ms ease;
          &:hover {
            background: rgba(164, 164, 164, 0.218);
            transition: 100ms ease;
            .hovEle {
              display: block;
            }
          }
        }
      }
    }
    .right {
      flex: 3;
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        background-color: #f9f8f2;
        border-radius: 10px;
        .tile {
          display: flex;
          align-items: center;
          gap: 10px;
          svg {
            height: 20px;
          }
          div {
          }
        }
      }
      .title {
        .tile {
          // width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 10px;
          h1 {
            font-weight: 700;
          }
          & > button {
            background-color: rgb(32, 151, 255);
            border-radius: 10px;
            padding: 2px 10px;
            color: white;
            transition: 100ms ease;
            border: 1px solid rgb(32, 151, 255);
            &:hover {
              background-color: transparent;
              color: rgb(32, 151, 255);
              transition: 100ms ease;
              font-weight: 600;
            }
          }
          p {
            span {
              color: rgb(0, 168, 50);
              &.red {
                color: red;
              }
            }
          }
          #stockAdjust {
            // background-color: rgb(205, 234, 255);
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            width: 100vw;
            background: rgba(0, 0, 0, 0.445);
            .top {
              padding: 20px;
              border-bottom: 1px solid rgb(195, 195, 195);
              display: flex;
              justify-content: space-between;
              align-items: center;
              &.t {
                margin: 0 10px;
                justify-content: start;
                gap: 30px;
              }
              div {
                display: flex;
                align-items: center;

                gap: 10px;
                h1 {
                  font-size: 1.2rem;
                  font-weight: 600;
                  margin-right: 30px;
                }
              }
              .l {
                .toggle {
                  width: 50px;
                  height: 25px;
                  background-color: #4eaaff;
                  border-radius: 50px;
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  transition: 100ms ease;
                  &.opp {
                    justify-content: end;
                    transition: 100ms ease;
                  }
                  .button {
                    width: 20px;
                    margin: 0px 4px;
                    height: 20px;
                    background-color: #1653f9;
                    border-radius: 50px;
                  }
                }
              }
              .r {
                svg {
                  fill: rgb(129, 129, 129);
                  height: 1.4rem;
                  margin-left: 20px;
                }
              }
            }
            .c1 {
              padding: 20px;
              & > div {
                display: flex;
                align-items: center;
                gap: 20px;
                margin: 10px;
                select {
                  width: 200px;
                  padding: 10px;

                  border-bottom: 1px solid #ccc;
                  height: fit-content;
                }
              }
            }
            .c2 {
              .t {
                padding-bottom: 0;
                button {
                  padding: 10px 20px;
                  color: gray;
                  font-weight: 600;
                  &.active {
                    border-bottom: 2px solid red;
                    color: red;
                  }
                  &:hover {
                    color: black;
                  }
                }
              }
              .div {
                padding: 20px;
                div {
                  display: flex;
                  gap: 20px;
                  margin: 10px 0;
                }
                &.s {
                  width: max-content;
                  display: grid;
                  gap: 20px;
                  grid-template-columns: 1fr 1fr 1fr;
                }
              }
            }
            .c3 {
              padding: 20px;
              display: flex;
              justify-content: end;
              width: 100%;
              gap: 20px;
              button {
                border-radius: 5px;
                padding: 10px 30px;
                background-color: rgb(32, 32, 32);
                color: white;
                font-weight: 700;
                &:first-child {
                  background-color: rgb(255, 255, 255);
                  font-weight: 700;
                  border: 1px solid rgb(32, 32, 32);
                  color: rgb(32, 32, 32);
                }
                transition: 100ms ease;
                &:hover {
                  background-color: rgb(0, 0, 0);
                  color: white;
                  font-weight: 700;
                  transition: 100ms ease;
                }
              }
            }
          }
        }
        min-height: 10vw;
        padding: 10px;
        background-color: #f6f6f6;
        border-radius: 10px;
        margin-bottom: 10px;
      }
      .content {
        // background-color: #f9f8f2;
        background-color: #f8fdff;
        border: 1px solid #d4f7ea;
        .t {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px;
          h1 {
            font-size: 1.2vw;
            font-weight: 600;
          }
          .search {
            border: 1px solid grey;
            border-radius: 50px;
            display: flex;
            align-items: center;
            svg {
              margin: 5px 10px;
              height: 15px;
            }
            input {
              height: 100%;
              border: none;
              background-color: transparent;
              font-size: 1.2vw;
            }
          }
        }
        .cl {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 20px;
          // border-bottom: 1px solid gray;
          margin: 10px;
          border-radius: 10px;
          background-color: #d0e4d9;
          &.top {
            background-color: #bedfd3;
            border-radius: 10px;
          }
          &:nth-child(odd) {
            background-color: #def0e9;
          }
          p {
            font-weight: 600;
            flex: 1;
            text-align: center;
            &.grey {
              font-weight: 400;
            }
          }
        }
      }
    }
  }
  .service {
    height: 90vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: zoomIn 150ms ease-in;

    button {
      padding: 5px 20px;
      background-color: orange;
      border-radius: 10px;
      color: white;
      margin: 20px;
      font-weight: 600;
    }
    p {
      font-weight: 500;
    }
  }
  @keyframes zoomIn {
    0% {
      scale: 0.8;
      opacity: 0;
    }
    90% {
      opacity: 1;
      scale: 1.05;
    }
    100% {
      opacity: 1;
      scale: 1;
    }
  }
}
#addItem {
  // background-color: rgb(205, 234, 255);
  height: 100%;
  padding: 10px;
  .container {
    background-color: white;
    border-radius: 0px;
    // box-shadow: 0 0 10px rgba(111, 111, 111, 0.396);
    min-height: 60vh;
    height: fit-content;
    .top {
      padding: 20px;
      border-bottom: 1px solid rgb(195, 195, 195);
      display: flex;
      justify-content: space-between;
      align-items: center;
      &.t {
        margin: 0 10px;
        justify-content: start;
        gap: 30px;
      }
      div {
        display: flex;
        align-items: center;

        gap: 10px;
        h1 {
          font-size: 1.2rem;
          font-weight: 600;
          margin-right: 30px;
        }
      }
      .l {
        .toggle {
          width: 50px;
          height: 25px;
          background-color: #4eaaff;
          border-radius: 50px;
          display: flex;
          justify-content: start;
          align-items: center;
          transition: 100ms ease;
          &.opp {
            justify-content: end;
            transition: 100ms ease;
          }
          .button {
            width: 20px;
            margin: 0px 4px;
            height: 20px;
            background-color: #1653f9;
            border-radius: 50px;
          }
        }
      }
      .r {
        svg {
          fill: rgb(129, 129, 129);
          height: 1.4rem;
          margin-left: 20px;
        }
      }
    }
    .c1 {
      padding: 20px;
      & > div {
        display: flex;
        align-items: center;
        gap: 20px;
        select {
          width: 200px;
          padding: 10px;
          border-bottom: 1px solid #ccc;
          height: fit-content;
        }
      }
    }
    .c2 {
      .t {
        padding-bottom: 0;
        padding-top: 0;
        button {
          padding: 10px 20px;
          color: gray;
          font-weight: 600;
          &.active {
            border-bottom: 2px solid red;
            color: red;
          }
          &:hover {
            color: black;
          }
        }
      }
      .div {
        padding: 20px;
        div {
          display: flex;
          gap: 20px;
          margin: 10px 0;
        }
        &.s {
          width: max-content;
          display: grid;
          gap: 20px;
          grid-template-columns: 1fr 1fr 1fr;
        }
      }
    }
    .c3 {
      padding: 20px;
      display: flex;
      justify-content: end;
      width: 100%;
      gap: 20px;
      button {
        border-radius: 5px;
        padding: 10px 30px;
        background-color: rgb(32, 32, 32);
        color: white;
        font-weight: 700;
        &:first-child {
          background-color: rgb(255, 255, 255);
          font-weight: 700;
          border: 1px solid rgb(32, 32, 32);
          color: rgb(32, 32, 32);
        }
        transition: 100ms ease;
        &:hover {
          background-color: rgb(32, 32, 32);
          color: white;
          font-weight: 700;
          transition: 100ms ease;
        }
      }
    }
  }
}


.dropdown {
  position: relative;
  display: inline-block;

  .dropdown-button {
    padding: 0 2px;
    // background-color: #f1f1f1;
    // border: none;
    cursor: pointer;
    z-index: 0;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    display: block;
    padding: 0;
    margin: 0;
    white-space: nowrap;
    list-style-type: none;
    background-color: #fff;
    border: 1px solid #ccc;
    animation: fadeIn 200ms ease;

    transform: translateX(-100%);
    li {
      padding: 8px 16px;
      text-wrap: nowrap;
      cursor: pointer;
      &:hover {
        background-color: #f1f1f1;
      }
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  display: block;
  padding: 0;
  margin: 0;
  white-space: nowrap;
  list-style-type: none;
  background-color: #fff;
  border: 1px solid #ccc;
  animation: fadeIn 200ms ease;

  transform: translateX(-100%);
  li {
    padding: 5px 10px;
    font-size: 0.8rem;
    text-wrap: nowrap;
    cursor: pointer;
    &:hover {
      background-color: #f1f1f1;
    }
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.input-container {
  position: relative;
  margin: 10px 0;
  // margin-bottom: 20px;
  input {
    width: 200px;
    padding: 10px;
    border: none !important;
    border-radius: 5px;
    border: 2px solid #dadada !important;
    font-size: 16px;
    transition: border-color 0.3s;
    &:focus {
      border-radius: 5px;
      outline: none;
      border: 2px solid rgb(0, 58, 206) !important;
    }
  }

  label {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #808080;
    background: white;
    padding: 0 5px;
    font-size: 16px;
    pointer-events: none;
    transition: top 0.3s, font-size 0.3s, color 0.3s;
  }

  &.input-focused label,
  input:focus + label {
    top: 0;
    font-size: 12px;
    color: rgb(0, 58, 206);
  }
}

#addsales {
  min-height: 100vh;
  width: 100%;
  svg {
    height: 1rem;
  }
  & > .top {
    svg {
      height: 40px;
      width: 40px;
      fill: rgb(83, 82, 82);
      border-radius: 50px;
      transition: 100ms ease;
      padding: 10px;
      &:hover {
        background-color: rgba(128, 128, 128, 0.381);
        transition: 100ms ease;
      }
    }
    h1 {
      font-weight: 600;
      font-size: 1.3rem;
    }
    p {
      font-weight: 600;
      font-size: 1rem;
    }
    width: 100%;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    background-color: #f2f8f9;
    // background-color: #f9f8f2;
    div {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .toggle {
      width: 50px;
      height: 25px;
      background-color: #4eaaff;
      border-radius: 50px;
      display: flex;
      justify-content: start;
      align-items: center;
      transition: 100ms ease;
      &.opp {
        justify-content: end;
        transition: 100ms ease;
      }
      .button {
        width: 20px;
        margin: 0px 4px;
        height: 20px;
        background-color: #1653f9;
        border-radius: 50px;
      }
    }
  }
  .body {
    height: 100%;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .ai1 {
      display: flex;
      justify-content: space-between;
      // align-items: center;
      margin: 20px;

      .le {
        display: flex;
        gap: 10px;
      }
      .l {
        background-color: #21293414;
        display: flex;
        align-items: center;
        border-radius: 50px;
        height: fit-content;
        transition: 200ms ease;
        &:hover {
          background-color: #a9a9a964;
          transition: 200ms ease;
        }
        svg {
          margin: 15px;
          font-size: 1.3rem;
        }
        input {
          background: transparent;
          border: none;
          margin: 0;
          width: 250px;
          font-size: 0.9rem;
        }
      }
      .r {
        width: 300px;
        div {
          display: flex;
          justify-content: space-between;
          margin: 5px 0;
          // &.ne {
          //   flex-direction: column;
          // }
          p {
            text-align: center;
            width: 50%;
            height: fit-content;
            font-weight: 600;
          }
          // &.dropdown {
          //   position: absolute;
          //   background: white;
          //   flex-direction: column;
          //   right: 20px;
          //   width: 10vw;
          //   // overflow-x: hidden;
          //   overflow-y: auto;
          //   max-height: 30vh;
          //   // padding: 10px;
          //   box-shadow: 0 0 10px rgb(170, 170, 170);
          //   border-radius: 10px;
          // }
        }
        span {
          font-weight: 500;
        }
        input {
          // border-bottom: 1px solid black;
          font-weight: 700;
          width: 150px;
        }
      }
    }
    .ai2 {
      margin: 20px 0;
      font-size: 0.9rem;
      .cl {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        // border-bottom: 1px solid gray;
        margin: 10px 0;
        border-radius: 10px;
        // background-color: #d0e4d9;
        background-color: #d0f0f0;
        &.top {
          background-color: #bedddf;
          // background-color: #bedfd3;
          border-radius: 10px;
        }
        // &:hover {
        //   input {
        //     border: 1px solid grey;
        //   }
        // }
        &:nth-child(odd) {
          background-color: #def0f0;
          // background-color: #def0e9;
        }
        &:focus-within {
          input {
            border-left: 2px solid #ffffff;
            border-right: 2px solid #ffffff;
          }
          select {
            border-bottom: 2px solid #ffffff;
          }
        }
        input {
          width: 100%;
          background-color: transparent;
          border-radius: 0px;
          text-align: center;
          // border-bottom: 1px solid rgb(255, 255, 255);
          padding: 5px;
          margin: 5px;
          border: 2px solid rgba(255, 255, 255, 0);
          // flex: 1;
          &:focus {
            border: 2px solid rgb(255, 255, 255);
            outline: none;
          }
        }
        & > div {
          flex: 1;
        }
        select {
          flex: 1;
          padding: 5px 10px;
          background-color: transparent;
          border: none;
          width: 10vw;
          // border-bottom: 1px solid black;
          option {
            border-radius: 0;
          }
        }
        p {
          font-weight: 600;
          flex: 1;
          padding: 10px;
          text-align: center;
          &.grey {
            font-weight: 400;
          }
        }
      }
    }
    .ai3 {
      display: flex;
      justify-content: space-between;
      margin: 20px;
      div {
        display: flex;
        gap: 10px;
        align-items: baseline;
        width: fit-content;
        span {
          font-weight: 600;
        }

        &.r {
          margin-right: 10px;
          display: flex;
          align-items: baseline;
          border-bottom: 1px solid black;
          p {
            font-weight: 700;
            font-size: 1.1rem;
            margin: 0 20px;
            &.sub {
              font-size: 1rem;
            }
          }
          span {
            font-weight: 500;
            font-size: 0.9rem;
          }
        }
        &.a {
          display: flex;
          align-items: baseline;
          margin-right: 20px;
          input {
            width: 100px;
            border: none;
            border-bottom: 1px solid black;
            border-radius: 0;
            padding: 5px;
            text-align: end;
          }
        }
        & > button {
          font-weight: 600;
          margin: 0;
          border-radius: 50px;
          padding: 2px 10px;
          background-color: rgb(231, 231, 231);
        }
        input {
          border: 1px solid rgb(184, 184, 184);
          border-radius: 5px;
          &.in {
            width: 100px;
            &:focus {
              outline: none;
            }
          }
        }
        input {
          padding: 5px 30px;
          border: 1px solid black;
          border-radius: 5px;
          display: flex;
          align-items: center;
          gap: 10px;
          font-weight: 600;
          transition: 200ms ease;
          &:hover {
            background-color: rgba(59, 59, 59, 0.112);
            border-color: rgb(59, 59, 59);
            color: rgb(35, 35, 35);
            transition: 200ms ease;
          }
        }
        button,
        select {
          padding: 5px 20px;
          border: 1px solid black;
          border-radius: 5px;
          display: flex;
          font-size: 0.9rem;
          align-items: center;
          gap: 10px;
          font-weight: 600;
          transition: 200ms ease;
          background-color: transparent;
        }
        button {
          &:hover {
            background-color: rgb(59, 59, 59);
            border-color: rgb(59, 59, 59);
            color: white;
            svg {
              fill: white;
            }
            transition: 200ms ease;
          }
        }
      }
    }
    .ai4 {
      display: flex;
      justify-content: end;
      gap: 10px;
      margin: 10px 30px;
      margin-bottom: 30px;

      // input {
      //   padding: 5px 30px;
      //   border: 1px solid black;
      //   border-radius: 10px;
      //   display: flex;
      //   align-items: center;
      //   gap: 10px;
      //   font-weight: 600;
      //   transition: 200ms ease;
      //   &:hover {
      //     background-color: rgba(59, 59, 59, 0.112);
      //     border-color: rgb(59, 59, 59);
      //     color: rgb(35, 35, 35);
      //     transition: 200ms ease;
      //   }
      // }
      // button {
      //   padding: 5px 30px;
      //   border: 1px solid black;
      //   border-radius: 10px;
      //   display: flex;
      //   align-items: center;
      //   gap: 10px;
      //   font-weight: 600;
      //   transition: 200ms ease;
      //   &:hover {
      //     background-color: rgb(59, 59, 59);
      //     border-color: rgb(59, 59, 59);
      //     color: white;
      //     svg {
      //       fill: white;
      //     }
      //     transition: 200ms ease;
      //   }
      // }
    }
    .ai5 {
      display: flex;
      justify-content: end;
      gap: 20px;
      margin: 20px;
      label {
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 600;
        margin-right: 30px;
        font-size: 1.2rem;
      }
      button {
        padding: 5px 30px;
        // border: 1px solid black;
        border-radius: 5px;
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 1.2rem;
        font-weight: 500;
        &.save {
          background-color: #f9232329;
          color: #f92323;
          transition: 200ms ease;
          &:hover {
            transition: 200ms ease;
            font-weight: 600;
            background-color: #f92323;
            color: white;
          }
        }
        &.save1 {
          background-color: #f9232312;
          color: #f92323;
          transition: 200ms ease;
          &:hover {
            font-weight: 600;
            transition: 200ms ease;
            background-color: #f92323;
            color: white;
          }
        }
        &.share {
          border: 2px solid #346affbe;
          color: #346aff;
          svg {
            fill: #346aff;
          }
          transition: 200ms ease;
          &:hover {
            transition: 200ms ease;
            background-color: #346aff;
            svg {
              fill: white;
            }
            color: white;
          }
        }
      }
    }
  }
  .addExpenseCategoryDiv {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(19, 19, 19, 0.468);
    .content {
      background-color: white;
      padding: 10px;
      border-radius: 10px;
      width: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      .t {
        display: flex;
        justify-content: space-between;
      }
      h1 {
        font-weight: 600;
        font-size: 1.2rem;
        margin-right: 30px;
      }
      & > button {
        font-size: 1.2rem;
        font-weight: 600;
        background: #2990ff;
        border-radius: 10px;
        color: white;
        padding: 5px 50px;
      }
      select {
        padding: 10px;
        option {
          padding: 10px;
        }
      }
    }
  }
}

#CommingSoon {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .cont {
    background-color: #f2f2f2;
    border-radius: 20px;
    padding: 30px;
    animation: zoomIn 150ms ease-in;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      height: 40vh;
      width: auto;
    }
    h1 {
      margin-top: 10px;
      font-size: 1.2rem;
      font-weight: 500;
    }
  }
  @keyframes zoomIn {
    0% {
      scale: 0.8;
      opacity: 0;
    }
    90% {
      opacity: 1;
      scale: 1.05;
    }
    100% {
      opacity: 1;
      scale: 1;
    }
  }
}
#undone {
  width: 100%;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .cont {
    background-color: #f3e2ce;
    border-radius: 20px;
    padding: 30px;
    animation: zoomIn 150ms ease-in;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      height: 50vh;
      width: auto;
    }
    h1 {
      margin-top: 10px;
      font-size: 1.2rem;
      font-weight: 500;
    }
  }
  @keyframes zoomIn {
    0% {
      scale: 0.8;
      opacity: 0;
    }
    90% {
      opacity: 1;
      scale: 1.05;
    }
    100% {
      opacity: 1;
      scale: 1;
    }
  }
}
.search {
  position: relative;
  ul {
    position: absolute;
    background-color: rgb(250, 250, 250);
    width: 100%;
    li {
      padding: 10px;
      &.add {
        color: #0090d3;
        font-weight: 600;
      }
      &.extra {
        color: #454545;
        font-weight: 500;
      }
    }
  }
}

#saleInvoice {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f9f8f2;
    border-radius: 10px;
    .tile {
      display: flex;
      align-items: center;
      gap: 10px;
      svg {
        height: 20px;
      }
    }
  }
  .title {
    // min-height: 10vw;
    padding: 10px;
    background-color: #f4f4f4;
    // background-color: #d4f7ea;
    border-radius: 10px;
    margin: 10px 0;
    // box-shadow: -5px 5px 5px rgba(92, 92, 92, 0.482);
    // border: 1px solid gray;
    &.odd {
      margin: 10px;
      padding: 10px 20px;
      min-height: fit-content;
      background-color: #ffffff;
      box-shadow: none;
      // border: 1px solid rgb(174, 174, 174);
      .t {
        margin: 0 !important;
        .l {
          display: flex;
          button {
            margin: 0 10px;
            border: 1px solid gray;
            border-radius: 5px;
            padding: 5px 10px;
          }
          input {
            border-radius: 5px;
            padding: 5px 10px;
            line-height: normal;
            font-size: 1.1rem;
            border: 1px solid gray;
          }
        }
      }
    }
    .t {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      select {
        background-color: transparent;
        // padding: 10px 0;
        font-size: 1.3rem;
        font-weight: 500;
      }
      .r {
        display: flex;
        gap: 10px;
      }
      button {
        background-color: transparent;
        transition: 100ms ease;
        &:hover {
          background-color: rgba(167, 167, 167, 0.337);
          transition: 100ms ease;
        }
        display: flex;
        height: fit-content;
        padding: 5px 10px;
        border-radius: 50px;
        gap: 10px;
        justify-content: center;
        align-items: center;
      }
      svg {
        width: 16px;
      }
    }
    .b {
      display: flex;
      gap: 20px;
      h1 {
        // background-color: rgba(255, 255, 255, 0.427);
        padding: 5px;
        border-radius: 10px;
        font-weight: 500;
        span {
          font-size: 1.1rem;
          font-weight: 600;
        }
      }
      select {
        background-color: transparent;
        padding: 10px 0;
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }
  .content {
    background-color: #f9f8f2;
    .t {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      h1 {
        font-size: 1.2vw;
      }
      div {
        display: flex;
        gap: 10px;
      }
      button {
        padding: 5px 20px;
        border-radius: 50px;
        background-color: rgb(16, 88, 255);
        color: white;
      }
      .search {
        border: 1px solid grey;
        border-radius: 50px;
        display: flex;
        align-items: center;
        svg {
          margin: 5px 10px;
          height: 15px;
        }
        input {
          height: 100%;
          border: none;
          background-color: transparent;
          font-size: 1.2vw;
        }
      }
    }
    .cl {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      // border-bottom: 1px solid gray;
      margin: 10px;
      border-radius: 10px;
      background-color: #d0e4d9;
      &.top {
        background-color: #bedfd3;
        border-radius: 10px;
      }
      &:nth-child(odd) {
        background-color: #def0e9;
      }
      p {
        font-weight: 600;
        text-align: center;
        flex: 1;
        &.grey {
          font-weight: 400;
        }
        &.side {
          flex: none;
        }
      }
      svg {
        fill: black;
        height: 1rem;
      }
    }
  }
}

.topbar {
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding: 0 20px;
  margin-bottom: 20px;
  background-color: #f9f8f2;
  border-radius: 10px;
  button {
    padding: 5px 10px;
    font-weight: 700;
    border-bottom: 2px solid rgba(182, 0, 0, 0);
    transition: 200ms ease;
    &.selected {
      border-bottom: 2px solid red;
    }
    &:hover {
      border-bottom: 1px solid rgb(255, 174, 158);
      transition: 200ms ease;
    }
  }
}
#sale-Order {
  .topbar {
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding: 0 20px;
    margin-bottom: 20px;
    background-color: #f9f8f2;
    border-radius: 10px;
    button {
      padding: 5px 10px;
      font-weight: 700;
      border-bottom: 2px solid rgba(182, 0, 0, 0);
      transition: 200ms ease;
      &.selected {
        border-bottom: 2px solid red;
      }
      &:hover {
        border-bottom: 1px solid rgb(255, 174, 158);
        transition: 200ms ease;
      }
    }
  }
  .service {
    height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: zoomIn 150ms ease-in;
    img {
      height: 50vh;
      width: auto;
    }
    button {
      padding: 5px 20px;
      background-color: orange;
      border-radius: 10px;
      color: white;
      margin: 20px;
      font-weight: 600;
    }
    h1 {
      font-weight: 500;
      font-size: 1.4rem;
    }
    p {
      font-weight: 500;
      width: 60%;
      text-align: center;
    }
  }
  @keyframes zoomIn {
    0% {
      scale: 0.8;
      opacity: 0;
    }
    90% {
      opacity: 1;
      scale: 1.05;
    }
    100% {
      opacity: 1;
      scale: 1;
    }
  }
}

// #PlansPg {
//   height: 90%;
//   width: 100%;
//   display: flex;
//   justify-content: space-evenly;
//   align-items: center;
//   img {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-30%, -50%);
//     height: 70vh;
//     width: auto;
//     z-index: -1;
//   }
//   div {
//     height: 70%;
//     width: 20vw;
//     padding: 30px;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: space-between;
//     background-color: rgba(62, 138, 232, 0.598);
//     box-shadow: 5px 5px 10px rgba(89, 89, 89, 0.561);
//     transition: 100ms ease;
//     &:hover {
//       box-shadow: 5px 5px 10px rgba(89, 89, 89, 0.815);
//       transition: 100ms ease;
//     }
//     &.d2 {
//       height: 80%;
//       width: 25vw;
//       background-color: rgba(255, 240, 26, 0.769);
//       color: black;
//       button {
//         color: black;
//       }
//     }
//     color: white;
//     // border: 1px solid rgb(229, 249, 255);
//     border-radius: 10px;
//     h1 {
//       font-size: 1.8rem;
//       font-weight: 700;
//     }
//     h2 {
//       font-size: 1.4rem;
//       font-weight: 600;
//       text-align: center;
//     }
//     button {
//       background-color: rgba(252, 252, 252, 0.379);
//       // border: 1px solid rgb(255, 55, 55);
//       border-radius: 30px;
//       padding: 10px 30px;
//       font-weight: 600;

//       color: white;
//       transition: 100ms ease;
//       &:hover {
//         font-weight: 700;
//         background-color: rgba(255, 253, 253, 0.694);
//         color: rgb(0, 0, 0);
//         transition: 100ms ease;
//       }
//     }
//   }
// }

.tabs {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .tabs-header {
    height: fit-content;
    display: flex;
    align-items: center;
    background: rgb(232, 232, 232);
    // gap: 5px;
    .addTb {
      background-color: rgb(255, 255, 255);
      padding: 5px 10px;
      margin: 5px;
      height: fit-content;
      border-radius: 5px;
      svg {
        width: 15px;
        height: 15px;
        fill: black;
      }
    }
    .quit {
      &:hover {
        background-color: rgb(255, 40, 40);
        transition: 100ms ease;
        svg {
          fill: rgb(255, 255, 255);
        }
      }
      transition: 100ms ease;
      padding: 10px 30px;
      margin-left: auto;
      // margin-right: 5px;
      height: fit-content;
      // border-radius: 5px;
      svg {
        width: 20px;
        height: 20px;
        fill: black;
      }
    }
    .tab {
      background-color: transparent;
      padding: 0px 10px;
      margin: 6px;
      // border-left: 1px solid black;
      border-right: 1px solid black;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      button {
        padding: 2px;
        border-radius: 50px;
        svg {
          width: 15px;
          height: 15px;
          fill: black;
        }
      }
      &.open {
        // border-radius: 10px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        background-color: white;
        color: rgb(20, 20, 20);
        border: none;
        padding-bottom: 6px;
        margin-bottom: 0;
        button {
          padding: 2px;
          border-radius: 50px;
          svg {
            fill: rgb(20, 20, 20);
          }
        }
      }
    }
  }
  .tab-content {
    flex: 1;
    #QuickBilling {
      display: flex;
      height: 100%;
      .l {
        // flex: 2;
        .top {
          input {
            width: 100%;
            border: 2px solid rgb(197, 197, 197);
            outline: none;
            padding: 10px;
            font-size: 1.1rem;
            border-radius: 5px;
            &:hover {
              background-color: whitesmoke;
            }
            &:focus {
              border: 2px solid rgb(0, 136, 255);
            }
          }
          position: relative;
          ul {
            position: absolute;
            background-color: rgb(243, 243, 243);
            padding: 10px;
            box-shadow: 5px 5px 10px rgba(63, 63, 63, 0.498);
            width: 99%;
          }
          table {
            width: 100%;
            margin-top: 10px;
            border-left: 1px solid gray;
            border-right: 1px solid gray;

            tr {
              display: flex;
              th,
              td {
                // flex: 1;
                font-size: 0.7rem;
                width: 7vw;
                text-align: center;
                border-left: 1px solid gray;
                padding: 10px;
                &.name {
                  flex: 2;
                  width: 15vw;
                }
              }
              th {
                // border-bottom: 1px solid gray;
                background: whitesmoke;
                border: none;
              }
            }
          }
        }

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: inherit;
        .b {
          border-top: 1px solid rgb(202, 202, 202);
          // border-radius: 10px;
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        }
      }
      .r {
        flex: 1;
        display: flex;
        flex-direction: column;
        // gap: 10px;
      }
    }
  }
}

#loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  h1 {
    font-size: 1.2rem;
  }
  .container {
    --uib-size: 60px;
    --uib-color: black;
    --uib-speed: 1.5s;
    --uib-dot-size: calc(var(--uib-size) * 0.1);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: calc(var(--uib-size) * 0.64);
    width: calc(var(--uib-size) * 0.64);
  }

  @keyframes jump {
    0%,
    100% {
      transform: translateY(120%);
    }

    50% {
      transform: translateY(-120%);
    }
  }

  .dot {
    --uib-d1: -0.48;
    --uib-d2: -0.4;
    --uib-d3: -0.32;
    --uib-d4: -0.24;
    --uib-d5: -0.16;
    --uib-d6: -0.08;
    --uib-d7: -0;
    position: absolute;
    bottom: calc(var(--uib-bottom) + var(--uib-dot-size) / 2);
    right: calc(var(--uib-right) + var(--uib-dot-size) / 2);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: var(--uib-dot-size);
    width: var(--uib-dot-size);
    animation: jump var(--uib-speed) ease-in-out infinite;
    opacity: var(--uib-scale);
    will-change: transform;
    backface-visibility: hidden;
  }

  .dot::before {
    content: "";
    height: 100%;
    width: 100%;
    background-color: var(--uib-color);
    border-radius: 50%;
    transform: scale(var(--uib-scale));
    transition: background-color 0.3s ease;
  }

  .dot:nth-child(1) {
    --uib-bottom: 24%;
    --uib-right: -35%;
    animation-delay: calc(var(--uib-speed) * var(--uib-d1));
  }
  .dot:nth-child(2) {
    --uib-bottom: 16%;
    --uib-right: -6%;
    animation-delay: calc(var(--uib-speed) * var(--uib-d2));
  }
  .dot:nth-child(3) {
    --uib-bottom: 8%;
    --uib-right: 23%;
    animation-delay: calc(var(--uib-speed) * var(--uib-d3));
  }
  .dot:nth-child(4) {
    --uib-bottom: -1%;
    --uib-right: 51%;
    animation-delay: calc(var(--uib-speed) * var(--uib-d4));
  }
  .dot:nth-child(5) {
    --uib-bottom: 38%;
    --uib-right: -17.5%;
    animation-delay: calc(var(--uib-speed) * var(--uib-d2));
  }
  .dot:nth-child(6) {
    --uib-bottom: 30%;
    --uib-right: 10%;
    animation-delay: calc(var(--uib-speed) * var(--uib-d3));
  }
  .dot:nth-child(7) {
    --uib-bottom: 22%;
    --uib-right: 39%;
    animation-delay: calc(var(--uib-speed) * var(--uib-d4));
  }
  .dot:nth-child(8) {
    --uib-bottom: 14%;
    --uib-right: 67%;
    animation-delay: calc(var(--uib-speed) * var(--uib-d5));
  }
  .dot:nth-child(9) {
    --uib-bottom: 53%;
    --uib-right: -0.8%;
    animation-delay: calc(var(--uib-speed) * var(--uib-d3));
  }
  .dot:nth-child(10) {
    --uib-bottom: 44.5%;
    --uib-right: 27%;
    animation-delay: calc(var(--uib-speed) * var(--uib-d4));
  }
  .dot:nth-child(11) {
    --uib-bottom: 36%;
    --uib-right: 55.7%;
    animation-delay: calc(var(--uib-speed) * var(--uib-d5));
  }
  .dot:nth-child(12) {
    --uib-bottom: 28.7%;
    --uib-right: 84.3%;
    animation-delay: calc(var(--uib-speed) * var(--uib-d6));
  }
  .dot:nth-child(13) {
    --uib-bottom: 66.8%;
    --uib-right: 15%;
    animation-delay: calc(var(--uib-speed) * var(--uib-d4));
  }
  .dot:nth-child(14) {
    --uib-bottom: 58.8%;
    --uib-right: 43%;
    animation-delay: calc(var(--uib-speed) * var(--uib-d5));
  }
  .dot:nth-child(15) {
    --uib-bottom: 50%;
    --uib-right: 72%;
    animation-delay: calc(var(--uib-speed) * var(--uib-d6));
  }
  .dot:nth-child(16) {
    --uib-bottom: 42%;
    --uib-right: 100%;
    animation-delay: calc(var(--uib-speed) * var(--uib-d7));
  }

  .dot:nth-child(3) {
    --uib-scale: 0.98;
  }
  .dot:nth-child(2),
  .dot:nth-child(8) {
    --uib-scale: 0.96;
  }
  .dot:nth-child(1),
  .dot:nth-child(7) {
    --uib-scale: 0.94;
  }
  .dot:nth-child(6),
  .dot:nth-child(12) {
    --uib-scale: 0.92;
  }
  .dot:nth-child(5),
  .dot:nth-child(11) {
    --uib-scale: 0.9;
  }
  .dot:nth-child(10),
  .dot:nth-child(16) {
    --uib-scale: 0.88;
  }
  .dot:nth-child(9),
  .dot:nth-child(15) {
    --uib-scale: 0.86;
  }
  .dot:nth-child(14) {
    --uib-scale: 0.84;
  }
  .dot:nth-child(13) {
    --uib-scale: 0.82;
  }
}
.loading {
  position: absolute;
  bottom: 10%;
  left: 90%;
  svg {
    height: 50px;
    width: auto;
  }
}

#info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  height: 100vh;
  img {
    height: 40vh;
    width: auto;
  }
  h1 {
    font-size: 1.8rem;
    margin: 10px 0;
  }
  h2 {
    font-size: 1.4rem;
    margin: 0px 0;
    font-weight: 400;
    span {
      font-weight: 600;
    }
    svg {
      height: 1.2rem;
      width: auto;
      display: inline;
      fill: rgb(53, 53, 53);
    }
  }
  button {
    width: 200px;
    background: rgb(241, 241, 241);
    padding: 10px 30px;
    font-size: 1.2rem;
    transition: 100ms ease;
    &:hover {
      background: rgb(213, 213, 213);
      transition: 100ms ease;
    }
  }
}

#report {
  height: 92vh;
  display: flex;
  .sidebar {
    &::-webkit-scrollbar {
      width: 6px; /* Width of the scrollbar */
      height: 6px; /* Height of the scrollbar */
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent; /* Make the track invisible */
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #b9b9b9; /* Color of the scrollbar handle */
      transition: 200ms ease;
      border-radius: 3px; /* Rounded corners */
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      transition: 200ms ease;
      background: #555; /* Change color on hover */
    }

    /* Optionally, you can also style the scrollbar buttons (arrows) */
    &::-webkit-scrollbar-button {
      display: none; /* Hide scrollbar buttons */
    }
    height: 100%;
    list-style: none;
    width: 17vw;
    overflow-y: scroll;
    li {
      font-size: 1.5vh;
      padding: 5px 10px;
      font-weight: 500;
      margin-right: 3px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &.selected {
        background-color: rgb(230, 230, 230);
      }
      &.head {
        padding: 10px 10px;
        background: #dedcdc;
        font-size: 1.5vh;
        font-weight: 600;
        border-radius: 2px;
        margin-top: 10px;
        color: rgb(0, 0, 0);
      }
      &:hover {
        background: #dedede; /* Change color on hover */
      }
      svg {
        height: 15px;
        width: auto;
        fill: rgb(255, 208, 0);
        &.disabled {
          fill: rgb(141, 141, 141);
        }
      }
    }
  }
  .content {
    width: 100%;
    min-height: 100vh;
    padding-left: 10px;

    .base {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      h1 {
        margin: auto auto;
        font-weight: 600;
      }
    }
  }
}

#settings {
  display: flex;
  height: 100vh;
  .sidebar {
    height: 100%;
    background-color: rgb(9, 17, 31);
    color: white;
    width: 15vw;
    position: fixed;
    li {
      display: flex;
      padding: 2vh 3vh;
      justify-content: space-between;
      align-items: center;
      transition: 100ms ease;
      &:hover {
        transition: 100ms ease;
        background: rgba(255, 255, 255, 0.179);
      }
      &.t {
        font-weight: 600;
        margin-top: 10px;
        &:hover {
          background: none;
        }
      }
      &.selected {
        background-color: white;
        color: black;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      svg {
        height: 1rem;
        width: auto;
        z-index: 1;
        fill: white;
        // padding: 10px;
      }
    }
  }
  .closebtn {
    position: fixed;
    top: 3%;
    right: 6%;
    svg {
      width: 25px;
      height: 25px;
      fill: black;
    }
    border-radius: 50px;
    padding: 10px;
    transition: 100ms ease;
    &:hover {
      transition: 100ms ease;
      background: rgba(187, 187, 187, 0.345);
    }
  }
  .content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25vw, 1fr));
    width: 85vw;
    margin-left: 15vw;
    .tile {
      height: max-content;
      padding: 30px;
      h1 {
        font-size: 1.5vw;
        padding: 10px 0px;
        margin: 10px 0px;
        border-bottom: 1px solid gray;
        font-weight: 700;
        color: rgb(30, 30, 30);
        input {
          margin-right: 10px;
        }
        button {
          margin: 0 10px;
          padding: 0px 20px;
          border-radius: 5px;
          background: rgb(205, 205, 205);
          &:hover {
            background: rgb(159, 159, 159);
          }
        }
      }
      & > p {
        color: gray;
        font-weight: 600;
        font-size: 0.8vw;
      }
      & > div {
        display: flex;
        gap: 10px;
        margin: 15px 0;
        align-items: center;

        .range {
          width: 100%;
        }
        span {
          font-weight: 500;
        }
        input {
          border: 2px solid rgb(204, 204, 204);
          padding: 5px 10px;
        }
      }
    }
    .popup {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: #1b1b1b52;
      display: flex;
      justify-content: center;
      align-items: center;
      .popupC {
        padding: 20px;
        background-color: white;
        border-radius: 10px;
        border: 2px solid rgb(188, 188, 188);
        display: flex;
        gap: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        button {
          width: 100%;
          background: rgb(193, 193, 193);
          padding: 10px 20px;
          border-radius: 5px;
          font-weight: 600;
          &.add {
            background: rgb(255, 150, 150);
          }
        }
      }
    }
  }
}

.onlineStore {
  .service {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    & > h1 {
      margin: 10px;
      font-size: 2vw !important;
    }
    & > p {
      margin-bottom: 10px;
      color: gray;
    }
    .cl {
      // margin: 10px 0;
      padding: 20px 10px;
      border-bottom: 1px solid rgb(59, 59, 59);
      display: flex;
      width: 80%;
      justify-content: space-between;
      align-items: center;
      transition: 100ms ease;
      color: rgb(60, 60, 60);
      background-color: rgba(160, 222, 255, 0);
      &.i {
        color: black;
        border-bottom: 1px solid rgb(169, 169, 169);

        &:hover {
          transition: 100ms ease;
          background-color: rgb(245, 245, 245);
        }
      }
      &.selected {
        background-color: rgb(160, 222, 255);
      }
    }
    img {
      max-width: 80vw;
      height: auto;
    }
  }
}

#utils {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  width: 100%;
  h1 {
    font-size: 1.4vw;
  }
}

.barcode {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 80vh;
  h1 {
    font-size: 1.4rem;
    font-weight: 500;
    color: black;
    // text-shadow: 5px 0 5px rgb(185, 185, 185);
  }
  p {
    margin-bottom: 20px;
  }
  .items {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 60%;
    .item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 10px;
      border-bottom: 1px solid rgb(48, 48, 48);
      p {
        flex: 1;
        margin: 0 5px;
        text-align: center;
      }
      &:hover {
        background-color: rgb(232, 232, 232);
      }
    }
  }
  img {
    box-shadow: 0 0 10px gray;
    border-radius: 5px;
    margin: 10px 0;
  }
  button {
    padding: 10px 30px;
    border-radius: 5px;
    box-shadow: 0 0 10px gray;
    background-color: orange;
    margin: 30px 10px;
    color: white;
    &:hover {
      box-shadow: 0 0 10px gray;
      background-color: rgb(197, 128, 0);
    }
  }
}

.toggle {
  width: 50px;
  height: 25px;
  background-color: #4eaaff;
  border-radius: 50px;
  display: flex;
  justify-content: start;
  align-items: center;
  transition: 100ms ease;
  &.opp {
    justify-content: end;
    transition: 100ms ease;
  }
  .button {
    width: 20px;
    margin: 0px 4px;
    height: 20px;
    background-color: #1653f9;
    border-radius: 50px;
  }
}
